import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion"; // Importa motion desde framer-motion
import "./App.css";
import Estudios from "./componentes/estudios.js";
import Footer from "./componentes/footer.js";
import Hobbies from "./componentes/hobbies.js";
import Projects from "./componentes/projects.js";
import Portada from "./componentes/portada.js";
import Menu from "./componentes/menu.js";
import Work from "./componentes/work.js";
import Background from "./componentes/background.js";

import csv from "./assets/csv.webp";
import csvopen from "./assets/csv open.webp";
import csv_pdf from "./assets/CV_AdilemDobrasInformaticaSpain030625.pdf";
import send from "./assets/img/send.svg";

function App() {
  const containerRef = useRef(null);
  useEffect(() => {
    document.title = "Adilem Dobras Portfolio";
    console.log(
      "%c🚀 Hola, bienvenido a mi portafolio de Desarrolladora Full Stack! 💻",
      "font-size: 20px; font-weight: bold; color: #6610F2; background-color: #E83E8C; padding: 10px; border-radius: 5px; box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);"
    );
  }, []);
  // Define las variantes de animación
  const animationVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
  };
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = csv_pdf; // Ruta al archivo PDF que deseas descargar
    link.download = "CV_AdilemDobrasInformatica .pdf"; // Nombre del archivo que se descargará
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleClick = () => {
    window.location.href = "mailto:adobrasca@gmail.com";
  };
  return (
    <div className="App" ref={containerRef}>
      {" "}
      <div
        className="csv_downloader"
        onClick={handleDownload}
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Descargar curriculum vitae"
      >
        <img src={csv} className="csv-icon" alt="libro-cerrado" />
        <img src={csvopen} className="csv-open-icon" alt="libro-abierto" />
      </div>
      <Menu className="Menu" />
      <Background className="PortadaBackground" />
      <div className="container">
        <motion.div // Agrega motion.div alrededor de tu componente
          className="page_portada"
          variants={animationVariants} // Aplica las variantes de animación
          initial="hidden" // Define el estado inicial de la animación
          animate="visible" // Define el estado final de la animación
          transition={{ duration: 0.5 }} // Define la duración de la animación
        >
          <Portada className="Portada" />
        </motion.div>
        <div id="proyectos" className="page_proyectos">
          <Projects className="Proyectos" />
        </div>
        <div id="estudios" className="page_estudios">
          <Estudios className="Estudios" />
        </div>
        <div id="colaboraciones" className="page_work">
          <Work className="Proyectos" />
        </div>{" "}
        <div>
          <Hobbies className="Footer" />
        </div>
        <div className="page_footer">
          <Footer className="Footer" />
        </div>
      </div>
      <div className="reunion-button">
        <button type="button" onClick={handleClick} className="button">
          <span className="button__text">Trabajemos juntos</span>
          <span className="button__icon">
            <img src={send} alt="flecha"></img>
          </span>
        </button>
      </div>
    </div>
  );
}

export default App;
