import React from "react";
import "../styles/style__background.css";
const Background = () => {
  const particles = Array(4).fill(null);
  return (
    <div className="portada__container_background">
      <div className="portada__background">
        <div className="space background__black width__full position__relative">
          {particles.map((_, index) => (
            <div key={index} className="particle"></div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Background;
