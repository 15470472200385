import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PaginaPrincipal from "./PaginaPrincipal"; // Tu formulario
import OtraPagina from "./Prueba"; // La nueva página

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PaginaPrincipal />} />{" "}
        {/* Página principal */}
        <Route path="/otrapagina" element={<OtraPagina />} /> Nueva ruta
      </Routes>
    </Router>
  );
};

export default App;
