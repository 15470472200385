import React, { useEffect, useState } from "react";
import "../styles/style__hobbies.css";
import book1 from "../assets/book1.webp";
import book2 from "../assets/book2.webp";
import book3 from "../assets/book3.webp";
import book4 from "../assets/book4.webp";
import poemA from "../assets/poemas/poemaA.txt";
import poemB from "../assets/poemas/poemaB.txt";
import button from "../assets/main_button.webp";
import poemC from "../assets/poemas/poemaC.txt";
import poemD from "../assets/poemas/poemaD.txt";

const hobbiesData = [
  {
    img: book1,
    title: "Sola",
    subtitle: "Expresión del alma en palabras",
    moreInfo:
      "Es una forma de comunicación que conecta el alma humana, trascendiendo las fronteras del tiempo y espacio.",
    moreInfoFile: poemA, // Archivo con más información
  },
  {
    img: book2,
    title: "Cuando la noche se va",
    subtitle: "Capturando momentos únicos",
    description:
      "La fotografía es el arte de capturar imágenes que transmiten momentos, emociones y paisajes de la vida.",
    moreInfo:
      "Una imagen puede contar mil historias, y una buena fotografía es capaz de inmortalizar emociones y recuerdos.",
    moreInfoFile: poemB, // Archivo con más información
  },
  {
    img: book3,
    title: "Entre la Niebla del Desvane",
    subtitle: "Colores y emociones en lienzo",
    description:
      "La pintura es la expresión visual a través del uso de colores, formas y texturas en una superficie.",
    moreInfo:
      "Los artistas pintan para comunicar sus sentimientos, pensamientos y perspectivas del mundo de manera única.",
    moreInfoFile: poemC, // Archivo con más información
  },
  {
    img: book4,
    title: "Desierto",
    subtitle: "El lenguaje universal del alma",
    description:
      "La música es una forma de arte que utiliza el sonido y el ritmo para crear composiciones que evocan emociones.",
    moreInfo:
      "Desde la antigüedad, la música ha sido una herramienta para conectar a las personas y expresar la esencia humana.",
    moreInfoFile: poemD, // Archivo con más información
  },
];

const Hobbies = () => {
  const [selectedHobby, setSelectedHobby] = useState(null);
  const [title, setTitle] = useState("");
  const [moreInfo, setMoreInfo] = useState("");

  // Cargar el contenido del archivo .txt cuando se seleccione un hobby
  useEffect(() => {
    if (selectedHobby) {
      fetch(selectedHobby.moreInfoFile)
        .then((response) => response.text())
        .then((data) => {
          // Separar el contenido por líneas
          const lines = data.split("\n");
          const titleFromFile = lines[0]; // Primera línea será el título
          const body = lines.slice(1).join("\n"); // El resto será el contenido

          setTitle(titleFromFile); // Asignar el título
          setMoreInfo(body); // Asignar el contenido respetando los saltos de línea
        })
        .catch((error) => {
          console.error("Error al leer el archivo:", error);
        });
    }
  }, [selectedHobby]);
  return (
    <div className="hobbies flex__column all__centered">
      <h1 className="color__white first__font size__24px">Mis Hobbies</h1>
      <div className="hobbies-container">
        {hobbiesData.map((hobby, index) => (
          <div key={index} className="hobby-row">
            <img src={hobby.img} alt={hobby.title} className="hobby-image" />
            <div className="hobby-text color__white first__font">
              <h2>{hobby.title}</h2>
              <p>{hobby.subtitle}</p>
            </div>
            <div
              className="hobby-button-container"
              onClick={() => setSelectedHobby(hobby)}
            >
              <span className="hobby-button-text first__font size__12px">
                Ver Más
              </span>
              <img className="hobby-button" src={button} alt="Botón" />
            </div>
          </div>
        ))}
      </div>

      {/* Modal con fondo oscuro */}
      {selectedHobby && (
        <div className="modal-overlay" onClick={() => setSelectedHobby(null)}>
          <div
            className={`hobby-modal flex__column first__font ${
              selectedHobby ? "slideUp" : "slideDown"
            }`}
          >
            <h2>{title}</h2> {/* Mostrar el título leído del archivo */}
            <pre className="third__font">{moreInfo}</pre>{" "}
            {/* Mostrar el contenido respetando los saltos de línea */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Hobbies;
