import React from "react";
import "../styles/style__estudios.css";
import python from "../assets/python.webp";
import java from "../assets/java.webp";
import html from "../assets/html.webp";
import css from "../assets/css.webp";
import jss from "../assets/javascript.webp";
import sql from "../assets/sql.webp";
import service from "../assets/services.gif";
import react from "../assets/react.webp";
import angular from "../assets/angular.webp";
import thropy from "../assets/academics.webp";

const languages = [
  { name: "Python", img: python },
  { name: "Java", img: java },
  { name: "SQL", img: sql },
];

const fullstack = [
  { name: "HTML", img: html },
  { name: "CSS", img: css },
  { name: "JS", img: jss },
];

const services = [
  { name: "API SERVICES", img: service },
  { name: "SOAP SERVICES", img: service },
  { name: "DATA ANALYTICS", img: service },
];

const frameworks = [
  { name: "React", img: react },
  { name: "Angular", img: angular },
];

// Componente reutilizable para mostrar las habilidades
const SkillSection = ({ title, items }) => {
  return (
    <div className="first__skills flex__column">
      <h1 className="color__white second__font size__24px">{title}</h1>
      <div className="skills__stats"></div>
      {items.map((item, index) => (
        <div key={index} className="flex__column stats__languaje">
          <div className="languaje__title flex__row">
            <img src={item.img} alt={`${item.name} logo`} />
            <h3 className="first__font color__white size__14px">{item.name}</h3>
          </div>
          <div className="chargin__skill"></div>
        </div>
      ))}
    </div>
  );
};

function Estudios() {
  return (
    <div className="Estudios  ">
      <h1 className="estudios__titulo first__font color__white text__center size__24px">
        Estudios
      </h1>
      <div className="estudios__first flex__column justify__around">
        <SkillSection title="Lenguajes" items={languages} />
        <SkillSection title="Full Stack" items={fullstack} />
      </div>
      <div className="educacion flex__column justify__center position__relative align__center">
        <h1 className="color__white second__font">Estudios</h1>
        <div className="first__skills studies justify__center flex__row align__center">
          <img className="academics__img" src={thropy} alt="Academics" />
          <h1 className="color__white first__font size__16px">
            Universidad Europea
          </h1>
        </div>
        <div className="first__skills justify__center studies flex__row align__center">
          <img className="academics__img" src={thropy} alt="Academics" />
          <h1 className="color__white first__font size__16px">
            Colegio de La Salle
          </h1>
        </div>
      </div>
      <div className="estudios__first flex__column justify__around">
        <SkillSection title="Frameworks" items={frameworks} />
        <SkillSection title="Servicios" items={services} />
      </div>
    </div>
  );
}

export default Estudios;
