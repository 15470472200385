import React, { useState, useEffect } from "react";
import "../styles/style__postada.css";
import { subscribeToEvent } from "./eventBus";

const PortadaBackground = () => {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  useEffect(() => {
    const handleMenuExpanded = (newState) => {
      setIsMenuExpanded(newState);
    };

    subscribeToEvent("menuExpanded", handleMenuExpanded);

    return () => {};
  }, []);
  return (
    <div className="portada__container">
      <div className="portada__data">
        {" "}
        {isMenuExpanded ? null : (
          <div className="portada__middle">
            <h1>
              Full Stack<br></br>Developer
            </h1>
            <h2>Adilem Dobras</h2>
          </div>
        )}
        <div className="portada_bottom">
          <p>setScrollToContinue(event) </p>
          <div className="scroll-downs">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortadaBackground;
