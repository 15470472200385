import React, { useState, useEffect, useRef } from "react";
import "../stylesPrueba/style__formulario.css";
import "../styles/style__default.css";
import boton from "../assets/boton_juego.png";

const FormPanel = () => {
  const [formData, setFormData] = useState({
    nombre: "",
    apellido: "",
    correo: "",
    telefono: "",
  });

  const [countdown, setCountdown] = useState(3); // Estado para la cuenta regresiva
  const carouselRef = useRef(null);
  const dragStartX = useRef(0);
  const isDragging = useRef(false);

  // Manejar el cambio de los campos del formulario
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Desplazar el carrusel
  const scrollCarousel = (direction) => {
    if (carouselRef.current && carouselRef.current.children.length > 0) {
      const firstChild = carouselRef.current.children[0];
      const firstChildWidth = firstChild.getBoundingClientRect().width;
      const scrollAmount = firstChildWidth;

      const currentScroll = carouselRef.current.scrollLeft;
      let newScroll =
        direction === "next"
          ? currentScroll + scrollAmount
          : currentScroll - scrollAmount;

      carouselRef.current.scrollTo({
        left: newScroll,
        behavior: "smooth",
      });
    }
  };

  // Manejar el arrastre del carrusel
  const handleDragStart = (e) => {
    isDragging.current = true;
    dragStartX.current = e.clientX;
  };

  const handleDragEnd = () => {
    isDragging.current = false;
  };

  const handleDragMove = (e) => {
    if (!isDragging.current) return;
    const distance = e.clientX - dragStartX.current;
    carouselRef.current.scrollLeft -= distance;
    dragStartX.current = e.clientX;
  };

  useEffect(() => {
    // Iniciar la cuenta regresiva en el segundo panel
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer); // Limpiar el timeout
    }
  }, [countdown]);

  useEffect(() => {
    // Agregar los event listeners para el arrastre
    const carousel = carouselRef.current;
    if (carousel) {
      carousel.addEventListener("mousedown", handleDragStart);
      carousel.addEventListener("mousemove", handleDragMove);
      carousel.addEventListener("mouseup", handleDragEnd);
      carousel.addEventListener("mouseleave", handleDragEnd);

      // Para dispositivos táctiles (móvil)
      carousel.addEventListener("touchstart", (e) =>
        handleDragStart(e.touches[0])
      );
      carousel.addEventListener("touchmove", (e) =>
        handleDragMove(e.touches[0])
      );
      carousel.addEventListener("touchend", handleDragEnd);
    }

    // Limpieza al desmontar
    return () => {
      const carousel = carouselRef.current;
      if (carousel) {
        carousel.removeEventListener("mousedown", handleDragStart);
        carousel.removeEventListener("mousemove", handleDragMove);
        carousel.removeEventListener("mouseup", handleDragEnd);
        carousel.removeEventListener("mouseleave", handleDragEnd);
        carousel.removeEventListener("touchstart", (e) =>
          handleDragStart(e.touches[0])
        );
        carousel.removeEventListener("touchmove", (e) =>
          handleDragMove(e.touches[0])
        );
        carousel.removeEventListener("touchend", handleDragEnd);
      }
    };
  }, []);

  return (
    <div className="form-container">
      <div className="form-blur">
        <div className="carousel" ref={carouselRef}>
          {/* Paso 1 */}
          <div className="form-card">
            <h2 className="form-title">Registro</h2>
            <form className="form-content">
              <div>
                <label className="form-label">Nombre</label>
                <input
                  type="text"
                  name="nombre"
                  value={formData.nombre}
                  onChange={handleChange}
                  required
                  className="form-input"
                />
              </div>
              <div>
                <label className="form-label">Apellido</label>
                <input
                  type="text"
                  name="apellido"
                  value={formData.apellido}
                  onChange={handleChange}
                  required
                  className="form-input"
                />
              </div>
            </form>
          </div>

          {/* Paso 2: Panel con la cuenta regresiva */}
          <div className="form-card">
            <h2 className="form-title">Estamos a punto de empezar</h2>
            <div className="countdown">
              <p className="countdown-text">{countdown}</p>
            </div>
            {/* Aquí podrías poner más detalles si lo deseas */}
          </div>
        </div>

        {/* Botones para navegar en el carrusel */}
        <div className="carousel-buttons">
          <button
            onClick={() => scrollCarousel("prev")}
            className="carousel-btn prev-btn"
          >
            Anterior
          </button>
          <button
            onClick={() => scrollCarousel("next")}
            className="carousel-btn next-btn"
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormPanel;
