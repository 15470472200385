import React from "react";
import Formulario from "./componentesPrueba/formulario.js";
import Background from "./componentes/background.js";
import "./Prueba.css";

function Prueba() {
  return (
    <div>
      <Formulario>¡Hola, mundo!</Formulario>
      <Background></Background>
    </div>
  );
}

export default Prueba;
