import "../styles/style__footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      {/* Contenedor de la imagen repetida */}
      <div className="footer__pattern"></div>

      <div className="footer__rights color__black first__font">
        <p>© {new Date().getFullYear()} Adilem Dobras</p>
      </div>
    </footer>
  );
};

export default Footer;
