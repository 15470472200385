const eventListeners = {};

export const subscribeToEvent = (eventName, callback) => {
  if (!eventListeners[eventName]) {
    eventListeners[eventName] = [];
  }
  eventListeners[eventName].push(callback);
};

export const emitEvent = (eventName, data) => {
  const listeners = eventListeners[eventName];
  if (listeners) {
    listeners.forEach((callback) => {
      callback(data);
    });
  }
};
