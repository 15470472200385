import React, { useState, useEffect, useRef } from "react";
import "../stylesPrueba/style__formulario.css";
import "../styles/style__default.css";
import ReCAPTCHA from "react-google-recaptcha"; // Importar la librería de reCAPTCHA
import boton from "../assets/boton_juego.png";
import botton_not_pressed from "../assets/boton-not-pressed.png";
import botton_pressed from "../assets/boton-pressed.png";

import HCaptcha from "@hcaptcha/react-hcaptcha";
import { faL } from "@fortawesome/free-solid-svg-icons";

const FormPanel = () => {
  const [step, setStep] = useState(1); // Paso actual del formulario
  const [formData, setFormData] = useState({
    nombre: "",
    apellido: "",
    correo: "",
    telefono: "",
    genero: "",
    cedula: "",
  });
  const [pasaron10secs, setpasaron10secs] = useState(false); // Estado para la cuenta regresiva
  const [buttonSrc, setButtonSrc] = useState(botton_not_pressed);

  const [countdown, setCountdown] = useState(3); // Estado para la cuenta regresiva
  const [showButton, setShowButton] = useState(false); // Estado para controlar la visibilidad del botón
  const [recaptchaValue, setRecaptchaValue] = useState(null); // Estado para almacenar el valor de reCAPTCHA
  const [isButtonPressed, setIsButtonPressed] = useState(false); // Estado para saber si el botón está presionado
  const [buttonPressDuration, setButtonPressDuration] = useState(0); // Duración del tiempo que el botón ha sido presionado
  const [showRecaptcha, setShowRecaptcha] = useState(false); // Estado para mostrar el reCAPTCHA después de 10 segundos
  const [message, setMessage] = useState(""); // Mensaje que aparece cuando se suelta el botón
  const recaptchaSiteKey = "6Leh0fQqAAAAABQFrEREHAoVrcgK2eisc54vDaW6"; // Reemplaza esto con tu clave
  const [showTimer, setShowTimer] = useState(true); // Estado para mostrar/ocultar la etiqueta
  const [secondsLeft, setSecondsLeft] = useState(5);
  useEffect(() => {
    if (step === 2 && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer); // Limpiar el intervalo cuando el componente se desmonte o la cuenta llegue a cero
    } else if (countdown === 0) {
      setShowButton(true); // Cuando termine la cuenta regresiva, mostrar el botón
    }
  }, [step, countdown]);
  useEffect(() => {
    if (secondsLeft > 0 && showTimer) {
      const timer = setInterval(() => {
        setSecondsLeft((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer); // Limpiar el interval cuando el componente se desmonte o el temporizador termine
    }
  }, [secondsLeft, showTimer]);
  useEffect(() => {
    if (isButtonPressed) {
      const timer = setInterval(() => {
        setButtonPressDuration((prev) => prev + 1); // Incrementar el tiempo que el botón está presionado
      }, 500);

      return () => clearInterval(timer); // Limpiar el intervalo cuando el botón deje de estar presionado
    }
  }, [isButtonPressed]);

  useEffect(() => {
    if (buttonPressDuration >= 10) {
      setShowRecaptcha(true); // Mostrar el reCAPTCHA después de 10 segundos
    }
  }, [buttonPressDuration]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const captcha = useRef(null);
  const handleRecaptchaChange = () => {
    setMessage("usurio valiado");

    let count = 5; // Inicia la cuenta regresiva desde 5
    setMessage(`Tienes ${count} segundos para apretar el botón`);
    setpasaron10secs(true);
    const newIntervalId = setInterval(() => {
      count -= 1; // Decrementamos el contador
      setMessage(`Tienes ${count} segundos para apretar el botón`);

      if (count === 0) {
        clearInterval(newIntervalId); // Limpiamos el intervalo cuando llegue a 0
        setMessage("¡Tiempo agotado!"); // Mensaje final
        setIsCounting(false); // Cambiar el estado a que ya no está contando
      }
    }, 1000);

    // Guardamos el ID del intervalo y cambiamos el estado de la cuenta regresiva
    setIntervalId(newIntervalId);
    setIsCounting(true);
    // setRecaptchaValue(value); // Guardamos el valor de reCAPTCHA
  };
  const [intervalId, setIntervalId] = useState(null); // Para guardar el ID del intervalo
  const [isCounting, setIsCounting] = useState(false); // Para saber si está en cuenta regresiva

  const cancelCountdown = () => {};

  const handleMouseDown = () => {
    setButtonSrc(botton_pressed);
    setIsButtonPressed(true); // Cuando el botón es presionado
    setButtonPressDuration(0); // Resetear el tiempo de presión
    if (pasaron10secs == true) {
      setShowRecaptcha(false); // Mostrar el reCAPTCHA después de 10 segundos

      if (intervalId) {
        clearInterval(intervalId); // Limpiamos el intervalo si existe
        setMessage("¡Cuenta cancelada! SeguimosJugando");
        setIsCounting(false); // Cambiar el estado de la cuenta regresiva a falso
      }
      setButtonPressDuration(0); // Resetear el tiempo de presión
    }
    setpasaron10secs(false);
  };

  const handleMouseUp = () => {
    setButtonSrc(botton_not_pressed);

    setIsButtonPressed(false); // Cuando el botón es soltado
    if (buttonPressDuration < 10) {
      setMessage(
        "¡Perdiste! No mantuviste presionado el botón el tiempo suficiente."
      );
    } else if (buttonPressDuration >= 10) {
    }
  };

  const handleSubmit = () => {};

  return (
    <div className="form-container_">
      <div
        className="form-blur_"
        style={{
          background: step === 2 ? "transparent" : "rgba(56, 69, 75, 0.8)",
        }}
      >
        {/* Primera Tarjeta */}
        {step === 1 && (
          <div className="form-card fade-in">
            <h2 className="form-title color__white first__font">Registro</h2>
            <form className="form-content">
              <div>
                <label className="form-label first__font color__white size__12px">
                  Nombre
                </label>
                <input
                  type="text"
                  name="nombre"
                  value={formData.nombre}
                  onChange={handleChange}
                  required
                  className="form-input"
                />
              </div>
              <div>
                <label className="form-label first__font color__white size__12px">
                  Apellido
                </label>
                <input
                  type="text"
                  name="apellido"
                  value={formData.apellido}
                  onChange={handleChange}
                  required
                  className="form-input"
                />
              </div>
              <div
                className="position__relative contenedor-boton-iniciar"
                onClick={handleNextStep}
              >
                <p className="text-iniciar first__font">Iniciar</p>
                <img className="boton-iniciar" src={boton} alt="Iniciar" />
              </div>
            </form>
          </div>
        )}

        {/* Segunda Tarjeta */}
        {step === 2 && countdown > 0 && (
          <div className=" fade-in">
            <h2 className="form-title">Información Adicional</h2>
            <form className="form-content">
              <div className="countdown">
                <p className="countdown-text first__font">{countdown}</p>
              </div>
            </form>
          </div>
        )}

        {/* Cuando la cuenta regresiva termine, mostrar solo el botón */}
        {showButton && (
          <div className="center-button">
            {showRecaptcha && (
              <ReCAPTCHA
                className="recaptcha"
                sitekey={recaptchaSiteKey}
                onChange={handleRecaptchaChange}
              />
            )}
            <button
              className="boton-iniciar"
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onTouchStart={handleMouseDown}
              onTouchEnd={handleMouseUp}
              onClick={handleSubmit}
              disabled={isButtonPressed && !recaptchaValue}
              style={{ background: "none", border: "none", padding: 0 }}
            >
              <img
                className="boton-iniciar"
                src={buttonSrc}
                alt="Enviar"
                onContextMenu={(e) => e.preventDefault()} // Bloquea el menú contextual
                draggable={false} // Evita que se arrastre la imagen
              />
            </button>
            <p className="color__white first__font">{message}</p>{" "}
            {/* Mostrar mensaje si el botón se suelta antes de los 10 segundos */}
          </div>
        )}
      </div>
    </div>
  );
};

export default FormPanel;
