import React, { useState, useEffect } from "react";
// import Draggable from "react-draggable"; // Importar la librería

import repfinder_mobile from "../assets/repfinder/phone-repfinder-repuestos.png";
import fawtrucks_mobile from "../assets/fawtrucks/phone-fawtrucks-camiones.png";
import leads_mobile from "../assets/leads/phone-lead-form.png";
import helpdesk_mobile from "../assets/helpdesk/phone-ticket-table.png";

import fawtrucks_camiones from "../assets/fawtrucks/fawtrucks_camiones.png";
import leads from "../assets/leads/lead_form.png";
import repfinder from "../assets/repfinder/repfinder_shop.png";
import "../styles/style__default.css";

import "../styles/style__projects.css";
import helpdesk_ticket_table from "../assets/helpdesk/ticket-table.png";

const Project = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const projects = [
    {
      id: "helpdesk_project",
      title: "Helpdesk",
      company: "Ford Cincuentenario",
      frase:
        "Un sistema eficiente para gestionar proyectos y resolver problemas rápidamente.",
      description:
        "2019 - Sistema de tickets para gestionar incidencias de manera efectiva.",
      images: [windowWidth < 700 ? helpdesk_mobile : helpdesk_ticket_table],
    },
    {
      id: "fawtrucks_project",
      title: "FAW TRUCKS",
      company: "Faw Trucks",
      frase:
        "La forma más fácil y visual de explorar el catálogo de camiones de FAW.",
      description:
        "2020 - Plataforma interactiva para explorar el catálogo de camiones de FAW.",
      images: [windowWidth < 700 ? fawtrucks_mobile : fawtrucks_camiones], // Cambia la imagen según el ancho
    },
    {
      id: "fawtrucks_project2",
      title: "LEADS",
      company: "Cincuentenario",
      frase:
        "Gestiona de manera sencilla los proyectos y clientes potenciales de FAW.",
      description:
        "2021 - Sistema para gestionar proyectos y clientes potenciales de FAW.",
      images: [windowWidth < 700 ? leads_mobile : leads],
    },
    {
      id: "fawtrucks_project3",
      title: "REPFINDER",
      company: "Cincuentenario",
      frase:
        "Una herramienta eficaz para encontrar repuestos de camiones de forma rápida y precisa.",
      description:
        "2022 - Herramienta para encontrar repuestos de camiones rápidamente.",
      images: [windowWidth < 700 ? repfinder_mobile : repfinder],
    },
  ];
  return (
    <div className="Project__panel position__relative  overflow__hidden">
      <div className="panel__infographics flex__column position__relative">
        <h1 className="color__white first__font text__center">Proyectos</h1>

        {projects.map((project) => (
          <div className="infographics__projects grid__center" key={project.id}>
            <div className="projects__header flex__row align__center justify__between">
              <h2 className="color__white second__font">{project.title}</h2>
              <div className="header__company text__center color__white radius__100px flex__center">
                <i className={`bi bi-circle-fill ${project.company}`}></i>{" "}
                <p>{project.company}</p>
              </div>
            </div>

            {/* Contenedor con posición relativa */}
            <div
              className="fawtrucks_display position__relative flex__column"
              style={{ position: "relative" }}
            >
              {project.images.map((image, index) => (
                <img
                  key={index}
                  id="fawtrucks_secondimg"
                  src={image}
                  className="radius__10px"
                  alt={`project-${index}`}
                />
              ))}

              {/* Nota arrastrable con posición aleatoria dentro del contenedor */}
              {/* <Draggable>
                <div
                  className="note blur-background size__24px"
                  style={{
                    position: "absolute", // Asegura que la nota se posicione dentro del contenedor
                    top: `${Math.random() * (window.innerHeight * 0.5)}px`, // Posición aleatoria en el eje vertical
                    left: `${Math.random() * (window.innerWidth * 0.8)}px`, // Posición aleatoria en el eje horizontal
                    zIndex: 1, // Asegura que la nota no quede detrás de otros elementos
                  }}
                >
                  <p className="note__title size__16px third__font text__left">
                    Nota
                  </p>
                  <p className="note__description size__16px third__font text__left">
                    {project.description}
                  </p>
                </div>
              </Draggable> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Project;
