import React, { useState, useEffect } from "react";
import "../styles/style__menu.css";
import foto from "../assets/img/portada_imagen.jpeg";
import github from "../assets/github.webp";
import linkedin from "../assets/linkedin.webp";
import instagram from "../assets/instagram.webp";
import fire from "../assets/fire.gif";
import save from "../assets/floppy.webp";

import { emitEvent } from "./eventBus";

function Menu() {
  const [showMaxMenu, setShowMaxMenu] = useState(false);
  const [isCrossed, setIsCrossed] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [menuIconVisible, setmenuIconVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setmenuIconVisible(window.innerWidth <= 500); // Cambia el valor según lo necesites
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Ejecuta al inicio para establecer el estado inicial

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const toggleMenu = () => {
    const newState = !isExpanded;
    setIsExpanded(newState);
    emitEvent("menuExpanded", newState);
  };
  const toggleMaxMenu = () => {
    setShowMaxMenu(!showMaxMenu);
    setIsCrossed(!isCrossed);
  };
  return (
    <div className={`Menu ${showMaxMenu ? "background__black" : ""} `}>
      <i
        className={`bi bi-list ${
          menuIconVisible ? "visible" : "hidden"
        } color__white`}
        onClick={toggleMaxMenu}
      ></i>
      <div className="Menu_mini">
        <div className="Menu__access">
          <button
            className={`sidebar__menu-line ${isCrossed ? "crossed" : ""}`}
            aria-label={isCrossed ? "Close menu" : "Open menu"}
            onClick={toggleMaxMenu}
          ></button>
        </div>
        <div
          className={`Menu_me ${isExpanded ? "expanded" : ""}`}
          onClick={toggleMenu}
        >
          <img alt="imagen" src={foto}></img>
          {!isExpanded ? null : <div>Adilem Dobras</div>}
        </div>
        <div className="Menu_socialmedia">
          <a
            aria-label="Instagram"
            href="https://www.instagram.com/adilem_dobras"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="social-icons" src={instagram} alt="instagram" />
          </a>{" "}
          <a
            aria-label="Linkedin"
            href="https://www.linkedin.com/in/adilem-dobras-29555a249/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="social-icons" alt="linkedin" src={linkedin} />
          </a>{" "}
          <a
            aria-label="Github"
            href="https://github.com/adobrasca512"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="social-icons" alt="github" src={github} />
          </a>
        </div>
      </div>
      <div className={`Menu_max ${showMaxMenu ? "show" : ""}`}>
        <div className="Menu_options">
          <ul>
            <li>
              {" "}
              <i className="bi bi-caret-right-fill size__16px"></i>
              <a
                href="#estudios"
                onClick={toggleMaxMenu}
                className="first__font color__white text_decoration__none size__16px"
              >
                Estudios
              </a>
            </li>
            <li>
              {" "}
              <i className="bi bi-caret-right-fill size__16px"></i>
              <a
                href="#proyectos"
                onClick={toggleMaxMenu}
                className="first__font color__white text_decoration__none size__16px"
              >
                Proyectos
              </a>
            </li>{" "}
            <li>
              {" "}
              <i className="bi bi-caret-right-fill size__16px"></i>
              <a
                href="#colaboraciones"
                onClick={toggleMaxMenu}
                className="first__font color__white text_decoration__none size__16px"
              >
                Colaboraciones
              </a>
            </li>
          </ul>
          <div className="save-container">
            <img src={save} alt="Saving" className="floppy" />
            <p className="saving-text first__font">
              Saving Mode
              <span className="saving-dots">
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </span>
            </p>
          </div>

          <div className="fire-container">
            <img src={fire} alt="Fire" />
            {/* Chispas */}
            {[...Array(8)].map((_, i) => (
              <span key={i} className="spark"></span>
            ))}
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

export default Menu;
